<template>
<div>

  <v-sheet class="d-flex justify-center" :color="wsLIGHTCARD" >
    <v-sheet  class="pa-16 px-8" color="transparent" width="1200">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8"   >
          <div v-if="Object.keys(this.news).length > 0" class="pr-6">
              <v-img v-if="news.img" class="wsRoundedHalf"   :src="news.img" aspect-ratio="1.7"  />

              <v-img v-else class="wsRoundedHalf" src="@/assets/img/course_blank.jpg" aspect-ratio="1.7"  />

            <h2 class=" font-weight-medium mt-6 mb-6"
                :style="`color: ${wsDARKER}`"
                style="line-height: 1.2">
              {{ news.title }}
            </h2>

            <div class="newsContent" v-html="news.content"></div>


          </div>


          <v-skeleton-loader v-else type="card, article, paragraph" />

        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" >

          <div class="pl-0 pr-10">
            <h2 :style="`color : ${wsDARKER}`" class=" font-weight-light mb-5 mt-n2 bitter">Новини</h2>
            <v-sheet color="transparent" class="fill-height ">


              <div @click="getNews"  v-for="(news,i) in recentNews" :key="news.uuid">
                <v-divider v-if="i!==0" />
                <router-link :to="`/news/${news.alias}`" class="sideNews">
                  <div class="d-flex align-center mb-3 mt-3">
                    <div>
                      <v-img class="wsRoundedHalf" @click="getNews" :src="news.img" width="60px" height="60px"></v-img>
                    </div>
                    <div class="ml-3">
                      <div class="caption text--secondary">{{  news.published  }}</div>

                      <div>{{  news.title  }}</div>


                    </div>
                  </div>
                </router-link>
              </div>





            </v-sheet>


          </div>

        </v-col>
      </v-row>
    </v-sheet>
  </v-sheet>
</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "SingleNews",
  props: ['alias'],
  components : {
  },
  data() {
    return {
      news : {},
      recentNews : [],
      categoryNews : [],
    }
  },
  methods : {
    ...mapActions('news', [ 'GET_NEWS_MAIN_ONE_PUBLIC', 'GET_NEWS_MAIN_PUBLIC']),

    async initPage() {
      this.news = await this.GET_NEWS_MAIN_ONE_PUBLIC(this.alias)
    }
  },
  async mounted(){
    this.loading = true
    let result = await this.GET_NEWS_MAIN_PUBLIC('westudy')
    this.recentNews =  result.news;
    this.initPage()
  }
}
</script>

<style lang="scss" scoped>
.main_section {
  width:1100px
}
.sideNews  {
  text-decoration: none;
  line-height: 1.5 ;
  font-size: 0.75rem;
  font-weight: 400;

  font-family: sans-serif;
  color: #393939;
}
</style>